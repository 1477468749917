@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  font-family: 'Roboto';
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.card .table td, .card .table th 
{
  padding-left: 0rem;
  padding-right: 1.5rem;
}

.app-table {
  
  position: relative;
  z-index: 0;
  max-height: 100px;
  width: 100%;

  .fixed-cell {
    position: sticky;
    right: 0px;
    background:white;
    z-index: 2;

    &.left {
      left:0px;
      padding-left: 1rem;
      
    }
    
  }

  

  th {
    font-size: 14px !important;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    letter-spacing: 1px;
    font-weight: bolder !important;
    color:black;
    vertical-align: top;
    text-transform: capitalize !important;
    text-align: center !important;
    background-color: white;
    
    
  }

  tbody tr {
    color: black;
    font-family: 'Roboto' !important;
    padding-left:0px !important;
    padding-right: 0px !important;
  
    &.deactive {
      color:#9ea1a4;
    }

    &:hover {
      background-color: #e9e9ef;
    }

  }

  tbody td {
    font-family: 'Roboto' !important;
    font-size: 14px;
    text-align: center !important;
    letter-spacing: 1px;
    vertical-align: top;
  }
}

.navbar-top.border-bottom.navbar-dark {
  height: 55px;
}

.navbar-dropdown {
  background: none;
  border: none;
  height: 32px;
  display: flex;
  align-items: center;
  img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
  }

  h6 {
    margin-top: 10px;
    font-size: 16px;
    color: white;
    margin-left: 14px;
  }
}

.admin-navbar-toggle {
  display: none;
}

.notification-container {
  right: 35%;
  margin-top: 50px;
}

.divider {
  width:100%;
  height:5px;
  border-bottom: 1px solid #c8c8c8;
  margin-bottom: 8px;
}

@media only screen and (max-width: 1200px) {
  .admin-navbar-toggle {
    display: block;
  }

  .sidebar-navbar-toggle {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .brand-text {
    display: none;
  }
}

// New Login
.brand-name{
  font-weight: bold;
  color: #686E6C;
}



@media only screen and (max-width: 1070px) {
  .subtitle{
    font-size: 45px;
  }
}

@media only screen and (max-width: 990px) {
  .nav-link .nav-link-inner--text{
    color: #000000 !important;
  }
  .text-container{
    text-align: center;
  }
  .card-container{
    display: flex;
    justify-content: center;
  }
  .subtitle{
    font-size: calc(1.455rem + 2.46vw);
    margin-left: 0 !important;
  }

  .why-use{
    margin-top: 210px;
  }
}

