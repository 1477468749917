
.grid-background {
    background:#e9e9ef;
}

@media only screen and (max-width: 800px) {
    .button-container {
        .btn {
            margin-bottom: 10px;
        }
    }
    
  }

